import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import { Row, Col, Space, Divider } from 'antd';
import getConfig from 'next/config';
import { FacebookIcon, LinkedInIcon, YouTubeIcon } from 'components/Icons';
import Section from 'components/Section';
import Image from 'next/image';
import Logo from 'public/static/images/logo-grey.svg';
import RavenLogo from 'public/static/images/raven.svg';

const FooterContainer = styled(Row)`
    background-color: ${(props) => props.theme.colors.grey};
    padding-bottom: 20px;
`;

const LogotypeImage = styled(Image)`
    filter: grayscale(100%);
    margin: 15px;
`;

const FooterMenu = styled.div`
    a {
        color: ${({ theme }) => theme.colors.greyishBrown};
    }
    font-weight: 300;
`;

const AboutText = styled.div`
    color: ${({ theme }) => theme.colors.greyishBrown};
    font-weight: 300;
    p {
        margin-bottom: 0;
    }
`;

export const Footer = (): JSX.Element => {
    const { socials, name } = getConfig().publicRuntimeConfig;
    return (
        <FooterContainer justify="center">
            <Col span={20}>
                <footer>
                    <Row justify="center">
                        <Col style={{ padding: 12 }}>
                            <Space wrap>
                                {socials.fb && (
                                    <a
                                        href={socials.fb}
                                        title={`Odwiedź profil ${name} na Facebook-u`}
                                        rel="me nofollow"
                                    >
                                        <FacebookIcon style={{ color: '#464646' }} />
                                    </a>
                                )}
                                {socials.ln && (
                                    <a
                                        href={socials.ln}
                                        title={`Odwiedź profil ${name} na LinkedIn`}
                                        rel="me nofollow"
                                    >
                                        <LinkedInIcon style={{ color: '#464646' }} />
                                    </a>
                                )}
                                {socials.yt && (
                                    <a
                                        href={socials.yt}
                                        title={`Odwiedź profil ${name} na YouTube`}
                                        rel="me nofollow"
                                    >
                                        <YouTubeIcon style={{ color: '#464646' }} />
                                    </a>
                                )}
                            </Space>
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col>
                            <FooterMenu>
                                <Space
                                    wrap
                                    align="center"
                                    split={
                                        <Divider
                                            type="vertical"
                                            style={{
                                                borderLeft: '1px solid rgba(85, 85, 85, 0.6)'
                                            }}
                                        />
                                    }
                                    size="small"
                                    style={{
                                        justifyContent: 'center'
                                    }}
                                >
                                    <Link href="/o-tytule">O tytule</Link>
                                    <Link href="/reklama">Reklama</Link>
                                    <Link href="/kontakt">Kontakt</Link>
                                    <Link href="/archiwum">Archiwum</Link>
                                    <Link href="/regulamin">Regulamin serwisu</Link>
                                    <Link href="/polityka-prywatnosci">Polityka prywatności</Link>
                                </Space>
                            </FooterMenu>
                        </Col>
                    </Row>
                    <Section justify="center">
                        <Col span={20}>
                            <Row gutter={[24, 32]}>
                                <Col span={12}>
                                    <LogotypeImage
                                        width={145}
                                        height={49}
                                        layout="fixed"
                                        src={Logo}
                                        alt={name}
                                    />
                                    <AboutText>
                                        <p>Redakcje i biura: ul. Strzegomska 42AB 53-611 Wrocław</p>
                                        <p>tel. 71 7823 180</p>
                                        <p>rynekmotocyklowy@ravenmedia.pl</p>
                                    </AboutText>
                                </Col>
                                <Col span={12}>
                                    <Image
                                        width={144}
                                        height={49}
                                        layout="fixed"
                                        src={RavenLogo}
                                        alt="Raven Media"
                                    />
                                    <AboutText>
                                        Wydawca: Raven Media sp. z o.o. NIP 897-17-67-168 REGON
                                        021366963 Organ Rejestrowy: Sąd Rejonowy dla Wrocławia
                                        Fabrycznej VI Wydział Gospodarczy KRS 0000370285
                                    </AboutText>
                                </Col>
                            </Row>
                        </Col>
                    </Section>
                </footer>
            </Col>
        </FooterContainer>
    );
};

export default Footer;
